import axios from "axios";
import { useEffect, useState } from "react";
import { API, BILL, MENU } from "../API/constant";
import BillComponent from "../components/Bill/BillWithNameDish";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SkeletonBill from "../components/Bill/Skeleton";
import SnackBarAnnounce from "../components/Menu/SnackBarAnnounce";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function BasicTabs(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const OpenBill = props.bill.filter((item) => item.daytimeOut === undefined);
    const CloseBill = props.bill.filter(
        (item) => item.daytimeOut !== undefined
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Open Bill" {...a11yProps(0)} />
                    <Tab label="Close Bill" {...a11yProps(1)} />
                    <Tab label="All" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <BillComponent
                    bill={OpenBill}
                    menu={props.menu}
                    finishBill={props.finishBill}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BillComponent bill={CloseBill} menu={props.menu} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <BillComponent bill={props.bill} menu={props.menu} />
            </TabPanel>
        </Box>
    );
}

export default function BillPage() {
    const [Bill, setBill] = useState(null);
    const [menu, setMenu] = useState(null);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [announceCount, setAnnounceCount] = useState(0);

    function compare(a, b) {
        if (a.daytimeIn < b.daytimeIn) return 1;
        if (a.daytimeIn > b.daytimeIn) return -1;
        return 0;
    }

    const fetchBill = () => {
        axios.get(API + BILL).then((res) => {
            res.data.sort(compare);
            setBill(res.data);
        });
    };

    const fetchMenu = () => {
        axios.get(API + MENU).then((res) => {
            setMenu(res.data);
        });
    };

    const responseSuccess = (ThongBao) => {
        setSuccess(ThongBao);
        setAnnounceCount((announceCount) => announceCount + 1);
        setError("");
    };

    const responseFail = (ThongBao) => {
        setSuccess("");
        setAnnounceCount((announceCount) => announceCount + 1);
        setError(ThongBao);
    };

    useEffect(() => {
        fetchMenu();
        fetchBill();
    }, []);

    const finishBill = (idClose) => {
        axios
            .put(API + BILL + "/close/" + idClose, { daytimeOut: Date.now() })
            .then((res) => {
                fetchBill();
                responseSuccess("Close bill with id: " + idClose + " successfully!");
            })
            .catch((res) => {
                responseFail("Fail to close!");
            });
    };

    return (
        <div>
            <h1>This is the bill page</h1>
            {Bill && menu ? (
                <BasicTabs bill={Bill} menu={menu} finishBill={finishBill} />
            ) : (
                <SkeletonBill />
            )}
            <SnackBarAnnounce
                success={success}
                error={error}
                count={announceCount}
            />
        </div>
    );
}
