import { CardActionArea, CardActions, Grid, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InfoDishTable from "./InfoDishTable";

const GroupButton = (props) => {
    const handleIncrease = () => {
        props.setCount(props.count + 1);
    };

    const handleDecrease = () => {
        if (props.count > 0) props.setCount(props.count - 1);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
            }}
        >
            <IconButton
                color="primary"
                aria-label="minus to shopping cart"
                size="small"
                onClick={handleDecrease}
            >
                <RemoveCircleIcon />
            </IconButton>
            <Typography>{props.count}</Typography>
            <IconButton
                color="primary"
                aria-label="add to shopping cart"
                size="small"
                onClick={handleIncrease}
            >
                <AddCircleIcon />
            </IconButton>
        </div>
    );
};

const DishCard = (props) => {
    const [count, setCount] = useState(0);
    const refInfoDish = useRef(null);

    const ImageDish = (image) => {
        if (image === undefined)
            return "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e";
        else return image;
    };

    const UpdateToOrder = () => {
        const found = props.refCurrentOrder.current.find(
            (element) => element.id === props.dish.id
        );
        if (found === undefined) {
            if (count > 0) {
                props.refCurrentOrder.current.push({
                    id: props.dish.id,
                    count: count,
                });
                props.setDataForDialog(props.refCurrentOrder.current);
            }
        } else {
            if (count === 0) {
                const newArray = props.refCurrentOrder.current.filter(
                    (element) => element.id !== props.dish.id
                );
                props.refCurrentOrder.current = newArray;
                props.setDataForDialog(newArray);
            } else {
                const newArray = props.refCurrentOrder.current.map(
                    (item, index) => {
                        if (item.id === props.dish.id)
                            return { id: item.id, count: count };
                        else return item;
                    }
                );
                props.refCurrentOrder.current = newArray;
                props.setDataForDialog(newArray);
            }
        }
    };

    const handleOpenDialog = () => {
        refInfoDish.current.openDialog();
    }

    useEffect(() => {
        UpdateToOrder();
    }, [count]);

    return (
        <Card elevation={10} sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={handleOpenDialog}>
                <CardMedia
                    component="img"
                    image={ImageDish(props.dish.image[0])}
                    alt="Food"
                    loading="lazy"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.dish.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.dish.price} VND
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Grid container justifyContent="flex-end" alignItems="baseline">
                    <GroupButton count={count} setCount={setCount} />
                </Grid>
            </CardActions>
            <InfoDishTable dish={props.dish} ref={refInfoDish} />
        </Card>
    );
};

export default DishCard;
