import { Box, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { API, BILL } from "../API/constant";
import SnackBarAnnounce from "../components/Menu/SnackBarAnnounce";
import QRcode from "../components/QRcode/qrcode";

export default function GenerateBill() {
    const [tableId, setTableId] = useState(0);
    const [QRdisplay, setQRdisplay] = useState(false);
    const [Link, setLink] = useState("");

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [announceCount, setAnnounceCount] = useState(0);

    const uid = uuidv4();
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setTableId(event.target.value);
    };

    const responseSuccess = (ThongBao) => {
        setSuccess(ThongBao);
        setAnnounceCount((announceCount) => announceCount + 1);
        setError("");
    };

    const responseFail = (ThongBao) => {
        setSuccess("");
        setAnnounceCount((announceCount) => announceCount + 1);
        setError(ThongBao);
    };

    const handleSubmit = (event) => {
        setLoading(true);
        axios
            .post(API + BILL, {
                id: uid,
                table: tableId,
                daytimeIn: Date.now(),
            })
            .then((res) => {
                setLoading(false);
                setQRdisplay(true);
                responseSuccess("Create bill with id" + uid + " Successfully!");
                setLink(window.location.host + "/table/" + uid);
            })
            .catch((res) => {
                responseFail("Fail to create bill");
            });
        event.preventDefault();
    };

    return (
        <Box
            component="form"
            sx={{
                m: 3,
                maxWidth: 100,
            }}
            noValidate
            autoComplete="off"
        >
            <Box>
                <TextField
                    id="outlined-basic"
                    label="Table"
                    variant="outlined"
                    value={tableId}
                    onChange={handleChange}
                    type="number"
                />
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    Create
                </Button>
            </Box>

            {loading === true && <CircularProgress sx={{ mt: 1 }} />}
            {QRdisplay && (
                <Box sx={{ pt: 4 }}>
                    <QRcode link={Link} />
                </Box>
            )}
            <SnackBarAnnounce
                success={success}
                error={error}
                count={announceCount}
            />
        </Box>
    );
}
