import { Box, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { API, MENU } from "../API/constant";
import MenuCard from "../components/Menu/MenuCard";
import FormDialog from "../components/Menu/FormDialog";
import SnackBarAnnounce from "../components/Menu/SnackBarAnnounce";
import SkeletonMenu from "../components/Menu/Skeleton";

const Menu = () => {
    const [menu, setMenu] = useState(null);
    const id = useRef(0);
    const name = useRef("");
    const description = useRef("");
    const price = useRef(0);
    const image = useRef([]);

    const [announceCount, setAnnounceCount] = useState(0);
    const [success, setSuccess] = useState("Successful add menu!");
    const [error, setError] = useState("Error add menu!");

    const clearInput = () => {
        id.current = 0;
        name.current = "";
        description.current = "";
        price.current = 0;
        image.current = [];
    };

    const fetchDataMenu = () => {
        axios
            .get(API + MENU)
            .then((res) => {
                console.log(res.data);
                setMenu(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    };

    const deleteDishFromMenu = (id) => {
        axios
            .delete(API + MENU + "/" + id)
            .then((res) => {
                setSuccess("Successful delete!");
                setAnnounceCount(announceCount + 1);
                fetchDataMenu();
            })
            .catch((res) => {
                setSuccess("");
                setError(res.response.data.message);
                setAnnounceCount(announceCount + 1);
            });
    };

    useEffect(() => {
        fetchDataMenu();
    }, []);

    const addMenu = () => {
        if (
            id.current.length === 0 ||
            name.current.length === 0 ||
            description.current.length === 0 ||
            price.current.length === 0
        ) {
            setAnnounceCount(announceCount + 1);
            setError("You need to fill all input!");
            setSuccess("");
            return;
        } else {
            axios
                .post(API + MENU, {
                    id: id.current,
                    name: name.current,
                    description: description.current,
                    price: price.current,
                    image: image.current,
                })
                .then((res) => {
                    console.log(res);
                    fetchDataMenu();
                    setSuccess("Successful add menu!");
                    setAnnounceCount(announceCount + 1);
                    clearInput();
                })
                .catch((res) => {
                    setSuccess("");
                    setError(res.response.data.message);
                    setAnnounceCount(announceCount + 1);
                    clearInput();
                });
        }
    };

    const GridMenuCard = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    {props.menu.map((item, index) => (
                        <Grid item xs={6} md={3} key={index}>
                            <MenuCard dish={item} delete={deleteDishFromMenu}/>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    return (
        <Box>
            <FormDialog
                refId={id}
                refName={name}
                refDescription={description}
                refPrice={price}
                refImage={image}
                addMenu={addMenu}
            />

            {menu ? <GridMenuCard menu={menu} /> : <SkeletonMenu />}
            <SnackBarAnnounce
                success={success}
                error={error}
                count={announceCount}
            />
        </Box>
    );
};

export default Menu;
