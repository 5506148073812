import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InfoIcon from '@mui/icons-material/Info';
import SubDialog from "../subComponent/Dialog";


export default function InfoDialog({dish}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} startIcon={<InfoIcon />}>
                Details
            </Button>
            <Dialog fullWidth open={open} onClose={handleClose} maxWidth={"lg"}>
                <SubDialog dish={dish}/>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Edit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
