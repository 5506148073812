import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReceiptIcon from "@mui/icons-material/Receipt";

const formatDate = (stringDate) => {
    const date = new Date(stringDate);
    return (
        date.toDateString() +
        " - " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
};

const GridTime = (props) => {
    if (props.timeout !== undefined)
        return (
            <Grid container spacing={2} maxWidth={700}>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timein)}
                </Grid>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timeout)}
                </Grid>
            </Grid>
        );
    else
        return (
            <Grid container spacing={1} maxWidth={500}>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timein)}
                </Grid>
            </Grid>
        );
};

const OpenClose = (props) => {
    if (props.timeout === undefined)
        return (
            <Typography
                variant="h6"
                sx={{ color: "#4caf50" }}
                aria-label="settings"
            >
                Open
            </Typography>
        );
    else
        return (
            <Typography
                variant="h6"
                sx={{ color: "#d50000" }}
                aria-label="settings"
            >
                Close
            </Typography>
        );
};

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function BillCard(props) {
    const [expanded, setExpanded] = React.useState(false);

    const menu = props.menu;
    const bill = props.bill;
    let total = 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleFinishBill = () => {
        props.finishBill(bill.id);
    };

    return (
        <Card sx={{ maxWidth: 1500, marginTop: 2 }} elevation={10}>
            <CardHeader
                action={<OpenClose timeout={bill.daytimeOut} />}
                title={`Bill: ${bill.id}`}
                subheader={
                    <GridTime
                        timein={bill.daytimeIn}
                        timeout={bill.daytimeOut}
                    />
                }
                sx={{
                    pb: 0,
                }}
            />

            <CardContent>
                <Typography variant="body1" color="text.secondary">
                    Table: {bill.table}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {props.finishBill && (
                    <Tooltip title="Finish Bill" placement="top">
                        <IconButton
                            onClick={handleFinishBill}
                            aria-label="Delete"
                        >
                            <ReceiptIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <hr></hr>
                    <Grid container spacing={2} maxWidth={700}>
                        {bill.order.map((smallOrder) => {
                            const dish = menu.find(
                                (element) => element.id === smallOrder.id
                            );
                            total += smallOrder.price * smallOrder.count;
                            return (
                                <>
                                    <Grid item xs={7}>
                                        <span>
                                            <Typography
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                + {dish.name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                x {smallOrder.count}
                                            </Typography>
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                        sx={{ textAlign: "right" }}
                                    >
                                        {smallOrder.price * smallOrder.count}{" "}
                                        VND
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <hr></hr>
                    <Grid container spacing={2} maxWidth={700}>
                        <Grid item xs={7}>
                            <Typography>Total</Typography>
                        </Grid>
                        <Grid item xs={5} sx={{ textAlign: "right" }}>
                            <Typography>{total} VND</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
}
