import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/home";
import GenerateBill from "./pages/generateBill";
import NoMatch from "./pages/no-match";
import TableAndMenu from "./pages/table";
import AllBill from "./pages/allBill";
import { Container } from "@mui/system";
import Menu from "./pages/Menu";
import AdminPage from "./pages/AdminPage";
import WaitingOrderPage from "./pages/WaitingOrder";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact index element={<Home />}></Route>
                <Route exact path="admin" element={<AdminPage />}>
                    <Route exact path="bill" element={<AllBill />}></Route>
                    <Route
                        exact
                        path="generateBill"
                        element={<GenerateBill />}
                    ></Route>
                    <Route exact path="menu" element={<Menu />}></Route>
                    <Route exact path="currentWaiting" element={<WaitingOrderPage />}></Route>
                    <Route path="*" element={<NoMatch />}></Route>
                </Route>
                <Route
                    exact
                    path="/table/:id"
                    element={<TableAndMenu />}
                ></Route>
                <Route exact path="*" element={<NoMatch />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
