import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { API, BILL, MENU, WAITING } from "../API/constant";
import NavBar from "./../components/Header/navbar";
import OrderDialog from "../components/Table/AllOrder";
import { Grid } from "@mui/material";
import DishCard from "../components/Table/DishCard";
import SkeletonMenu from "../components/Menu/Skeleton";
import { Container } from "@mui/system";
import { useRef } from "react";
import SnackBarAnnounce from "../components/Menu/SnackBarAnnounce";

export default function TableAndMenu() {
    const { id } = useParams();
    const [bill, setbill] = useState(null);
    const [menu, setmenu] = useState(null);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [announceCount, setAnnounceCount] = useState(0);

    const currentOrder = useRef([]);
    const refDialog = useRef(null);

    const fetchOrder = () => {
        axios
            .get(API + BILL + "/" + id)
            .then((res) => {
                setbill(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    };

    const fetchDataMenu = () => {
        axios
            .get(API + MENU)
            .then((res) => {
                setmenu(res.data);
            })
            .catch((res) => {
                console.log(res);
            });
    };

    const responseSuccess = (ThongBao) => {
        setSuccess(ThongBao);
        setAnnounceCount((announceCount) => announceCount + 1);
        setError("");
    };

    const responseFail = (ThongBao) => {
        setSuccess("");
        setAnnounceCount((announceCount) => announceCount + 1);
        setError(ThongBao);
    };

    const addOrderToWaiting = () => {
        if (currentOrder.current.length !== 0) {
            axios
                .post(API + WAITING, {
                    id: bill.id,
                    daytimeOrder: Date.now(),
                    order: currentOrder.current,
                })
                .then((res) => {
                    currentOrder.current = [];
                    responseSuccess("Place order successfully!");
                })
                .catch((res) => {
                    responseFail("Error");
                });
        } else {
            responseFail("No item in cart!");
        }
    };

    useEffect(() => {
        fetchOrder();
        fetchDataMenu();
    }, []);

    const setDataForDialog = (data) => {
        refDialog.current.setData(data);
    };

    const GridMenuCard = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    {props.menu.map((item, index) => (
                        <Grid item xs={6} md={3} key={index}>
                            <DishCard
                                dish={item}
                                refCurrentOrder={currentOrder}
                                setDataForDialog={setDataForDialog}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    return (
        <Box>
            <NavBar>
                {bill && menu && (
                    <OrderDialog
                        ref={refDialog}
                        order={bill.order}
                        menu={menu}
                        addOrderToWaiting={addOrderToWaiting}
                    />
                )}
            </NavBar>

            <Container maxWidth="xl" sx={{ pt: 3 }}>
                {menu ? <GridMenuCard menu={menu} /> : <SkeletonMenu />}
            </Container>

            <SnackBarAnnounce
                success={success}
                error={error}
                count={announceCount}
            />
        </Box>
    );
}
