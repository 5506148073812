import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import {
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

const ListImage = (props) => {
    const renderList = props.listOfImage.map((image, index) => (
        <ListItem
            key={index}
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => props.delete(index)}
                >
                    <DeleteIcon />
                </IconButton>
            }
        >
            <ListItemText
                sx={{ wordWrap: "break-word", maxWidth: "100%" }}
                primary={
                    <>
                        <img
                            src={`${image}`}
                            srcSet={`${image}?w=200&fit=crop&auto=format&dpr=2 2x`}
                            alt={"Food"}
                            loading="lazy"
                        />
                        <figcaption>{image}</figcaption>
                    </>
                }
            />
        </ListItem>
    ));
    return <List>{renderList}</List>;
};

export default function FormDialog({
    refId,
    refName,
    refDescription,
    refPrice,
    refImage,
    addMenu,
}) {
    const [open, setOpen] = React.useState(false);

    const [idDialog, setIdDialog] = React.useState(0);
    const [nameDialog, setNameDialog] = React.useState("");
    const [descriptionDialog, setDescriptionDialog] = React.useState("");
    const [priceDialog, setPriceDialog] = React.useState(0);
    const [imageURL, setImageURL] = React.useState(null);
    const [listOfImage, setlistOfImage] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeId = (event) => {
        setIdDialog(event.target.value);
        refId.current = event.target.value;
    };

    const handleChangeName = (event) => {
        setNameDialog(event.target.value);
        refName.current = event.target.value;
    };

    const handleChangeDescription = (event) => {
        setDescriptionDialog(event.target.value);
        refDescription.current = event.target.value;
    };

    const handleChangePrice = (event) => {
        setPriceDialog(event.target.value);
        refPrice.current = event.target.value;
    };

    const handleChangeImage = (event) => {
        setImageURL(event.target.value);
    };

    const updateImageURL = () => {
        if (imageURL.length === 0) {
            return;
        }
        const newArray = listOfImage.concat(imageURL);
        setlistOfImage(newArray);
        refImage.current = newArray;
        setImageURL("");
    };

    const deleteImageURL = (index) => {
        let clone = [...listOfImage];
        clone.splice(index, 1);
        setlistOfImage(clone);
    };

    const handleAddMenu = () => {
        setIdDialog("");
        setDescriptionDialog("");
        setNameDialog("");
        setPriceDialog("");
        setImageURL("");
        setlistOfImage([]);
        addMenu();
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} sx={{mt: 2, mb: 2}}>
                Add menu
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
                <DialogTitle>Add dish</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In order to add dish, you need to fill out all input
                        belows.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ID"
                        label="ID dish"
                        value={idDialog}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChangeId}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label="Name dish"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={nameDialog}
                        onChange={handleChangeName}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description of dish"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={descriptionDialog}
                        onChange={handleChangeDescription}
                    />

                    <TextField
                        margin="dense"
                        id="price"
                        label="Price of dish"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={priceDialog}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    VND
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangePrice}
                    />

                    <TextField
                        sx={{ flex: 1 }}
                        margin="dense"
                        id="ImageItem"
                        label="URL of Image"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={imageURL}
                        onChange={handleChangeImage}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    type="button"
                                    aria-label="add"
                                    onClick={updateImageURL}
                                >
                                    <AddIcon />
                                </IconButton>
                            ),
                        }}
                    />

                    <ListImage
                        listOfImage={listOfImage}
                        delete={deleteImageURL}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddMenu}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
