import React from "react";
import { Card, CardMedia} from "@mui/material";
import Carousel from "react-material-ui-carousel";

const CarouselMenu = ({ dish }) => {
    return (
        <Carousel sx={{ maxWidth: 500 }} swipe={true}>
            {dish.image.map((item, i) => (
                <Item key={i} item={item} />
            ))}
        </Carousel>
    );
};

function Item(props) {
    return (
        <Card elevation={10} sx={{ maxWidth: 500 }}>
            <CardMedia
                component="img"
                image={props.item}
                alt="Foodr"
                loading="lazy"
            />
        </Card>
    );
}

export default CarouselMenu;
