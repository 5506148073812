import * as React from "react";
import Dialog from "@mui/material/Dialog";
import SubDialog from "../subComponent/Dialog";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";


export default forwardRef(function InfoDishTable(props, ref) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openDialog() {
                    handleClickOpen();
                },
            };
        },
        []
    );

    return (
        <div>
            <Dialog fullWidth open={open} onClose={handleClose} maxWidth={"lg"}>
                <SubDialog dish={props.dish}/>
            </Dialog>
        </div>
    );
});
