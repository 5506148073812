import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import CarouselMenu from "../Menu/Carousel";

const SubDialog = ({dish}) => {
    return (
        <>
            <DialogTitle>{dish.name}</DialogTitle>
            <DialogContent>
                <CarouselMenu dish={dish} />
                <DialogContentText>{dish.description}</DialogContentText>
                <DialogContentText sx={{ "font-weight": "bold" }}>
                    {dish.price} VND
                </DialogContentText>
            </DialogContent>
        </>
    );
};

export default SubDialog;
