import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

function SkeletonMenu() {
    const skeleton = (
        <>
            <Skeleton variant="rectangular" height={200} />
            <Skeleton variant="text" width={150}/>
            <Skeleton variant="text" width={50}/>
            <Skeleton variant="rectangular" height={30} />
        </>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={3}>{skeleton}</Grid>
            <Grid item xs={6} md={3}>{skeleton}</Grid>
            <Grid item xs={6} md={3}>{skeleton}</Grid>
            <Grid item xs={6} md={3}>{skeleton}</Grid>
        </Grid>
    );
}

export default SkeletonMenu;
