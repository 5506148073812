import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const formatDate = (stringDate) => {
    const date = new Date(stringDate);
    return (
        date.toDateString() +
        " - " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
};

const GridTime = (props) => {
    if (props.timeout !== undefined)
        return (
            <Grid container spacing={2} maxWidth={700}>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timein)}
                </Grid>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timeout)}
                </Grid>
            </Grid>
        );
    else
        return (
            <Grid container spacing={1} maxWidth={500}>
                <Grid item xs={12} md={6}>
                    <AccessTimeIcon
                        sx={{
                            "vertical-align": "top",
                        }}
                    />
                    &nbsp;
                    {formatDate(props.timein)}
                </Grid>
            </Grid>
        );
};

const MenuWithIcon = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePushWaiting = () => {
        handleClose();
        props.handlePushWaiting();
    }

    const handleDeleteWaiting = () => {
        handleClose();
        props.handleDeleteWaiting();
    }

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={handlePushWaiting}>Push to order</MenuItem>
                <MenuItem onClick={handleDeleteWaiting}>Delete</MenuItem>
            </Menu>
        </div>
    );
};

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function WaitingCard(props) {
    const [expanded, setExpanded] = React.useState(false);

    const menu = props.menu;
    const itemWaiting = props.itemWaiting;
    let total = 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handlePushWaiting = () => {
        props.pushWaitingToOrder(itemWaiting._id);
    };

    const handleDeleteWaiting = () => {
        props.cancelWaiting(itemWaiting._id);
    };

    return (
        <Card sx={{ maxWidth: 1500, marginTop: 2 }} elevation={10}>
            <CardHeader
                action={
                    <MenuWithIcon
                        handlePushWaiting={handlePushWaiting}
                        handleDeleteWaiting={handleDeleteWaiting}
                    />
                }
                title={`Table: ${props.table}`}
                subheader={<GridTime timein={itemWaiting.daytimeOrder} />}
                sx={{
                    pb: 0,
                }}
            />

            <CardContent>
                <Typography variant="body1" color="text.secondary">
                    Bill: {itemWaiting.id}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <hr></hr>
                    <Grid container spacing={2} maxWidth={700}>
                        {itemWaiting.order.map((smallOrder) => {
                            const dish = menu.find(
                                (element) => element.id === smallOrder.id
                            );
                            total += dish.price * smallOrder.count;
                            return (
                                <>
                                    <Grid item xs={7}>
                                        <span>
                                            <Typography
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                + {dish.name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                x {smallOrder.count}
                                            </Typography>
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                        sx={{ textAlign: "right" }}
                                    >
                                        {dish.price * smallOrder.count} VND
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <hr></hr>
                    <Grid container spacing={2} maxWidth={700}>
                        <Grid item xs={7}>
                            <Typography>Total</Typography>
                        </Grid>
                        <Grid item xs={5} sx={{ textAlign: "right" }}>
                            <Typography>{total} VND</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
}
