import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Badge, Collapse, Grid, ListItemButton } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default forwardRef(function OrderDialog(props, ref) {
    const [open, setOpen] = React.useState(false);
    const [currentOrder, setcurrentOrder] = useState([]);
    const [ListOpenCO, setListOpenCO] = useState(false);
    const menu = props.menu;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCLickListOpenCO = () => {
        setListOpenCO(!ListOpenCO);
    };

    const handleClickPlaceOrder = () => {
        props.addOrderToWaiting();
        setcurrentOrder([]);
        setOpen(false);
    }

    const ReturnBillList = ({ orderProp }) => {
        let tmpTotal = 0;
        const BillList = orderProp.map((smallOrder, index) => {
            const dish = menu.find((element) => element.id === smallOrder.id);
            if (isNaN(smallOrder.price)) smallOrder.price = dish.price;
            tmpTotal += smallOrder.price * smallOrder.count;
            return (
                <>
                    <Grid item xs={7}>
                        <span>
                            <Typography sx={{ fontWeight: "bolder" }}>
                                + {dish.name}
                            </Typography>
                            <Typography color="text.secondary">
                                x {smallOrder.count}
                            </Typography>
                        </span>
                    </Grid>
                    <Grid item xs={5} sx={{ textAlign: "right" }}>
                        {smallOrder.price * smallOrder.count} VND
                    </Grid>
                </>
            );
        });

        const DivideBill = (
            <Grid item xs={12}>
                <Divider variant="middle" />
            </Grid>
        );

        const GridTotal = (
            <>
                <Grid item xs={7}>
                    <Typography>Total</Typography>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: "right" }}>
                    <Typography>{tmpTotal} VND</Typography>
                </Grid>
            </>
        );
        return (
            <>
                {BillList}
                {DivideBill}
                {GridTotal}
            </>
        );
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                setData(OrderCurrent) {
                    const asx = [...OrderCurrent];
                    setcurrentOrder(asx);
                },
            };
        },
        []
    );

    return (
        <div>
            <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={handleClickOpen}
            >
                <Badge badgeContent={currentOrder.length} color="error">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            Cart
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClickPlaceOrder}>
                            Place Order
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItemButton onClick={handleCLickListOpenCO}>
                        <ListItemText
                            primary="Order History"
                            sx={{ color: "#ef5350" }}
                        />
                        {ListOpenCO ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={ListOpenCO} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            spacing={2}
                            maxWidth={700}
                            sx={{ pl: 4, pr: 2, "background-color": "#ef9a9a" }}
                        >
                            <ReturnBillList orderProp={props.order} />
                        </Grid>
                    </Collapse>
                    <Divider>YOUR ORDER</Divider>
                    <Grid
                        container
                        spacing={2}
                        maxWidth={700}
                        sx={{ pl: 1, pr: 2 }}
                    >
                        <ReturnBillList orderProp={currentOrder} />
                    </Grid>
                </List>
            </Dialog>
        </div>
    );
});
