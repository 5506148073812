import { Button, CardActions, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoDialog from "./InfoDishDialog";


const MenuCard = (props) => {
    const ImageDish = (image) => {
        if (image === undefined)
            return "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e";
        else return image;
    };

    const handleDelete = () => {
        props.delete(props.dish.id);
    }

    return (
        <Card elevation={10} sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                image={ImageDish(props.dish.image[0])}
                alt="Live from space album cover"
                loading="lazy"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.dish.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.dish.price} VND
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container spacing={-10} rowSpacing={1} justifyContent="flex-start" alignItems="center">
                    <Grid item md={6} xs={12}><Button variant="outlined" startIcon={<DeleteIcon />} color="error"onClick={handleDelete}>Delete</Button></Grid>
                    <Grid item md={6} xs={12}><InfoDialog dish={props.dish}/> </Grid>
                </Grid>
            </CardActions>
            
        </Card>
    );
};

export default MenuCard;
