import React from "react";
import WaitingCard from "./WaitingCard";

const WaitingOrderComponent = (props) => {
    const menu = props.menu,
        Waiting = props.Waiting;
    return (
        <>
            {Waiting.map((item, key) => {
                const find = props.Bill.find(element => element.id === item.id);
                return (
                    <div key={key}>
                        <WaitingCard
                            itemWaiting={item}
                            menu={menu}
                            table={find.table}
                            cancelWaiting={props.cancelWaiting}
                            pushWaitingToOrder={props.pushWaitingToOrder}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default WaitingOrderComponent;
