import BillCard from "./BillCard";

export default function BillComponent(props) {
    const menu = props.menu;
    const bill = props.bill;

    return (
        <>
            {bill.map((item, key) => {
                return (
                    <div>
                        <BillCard bill={item} menu={menu} finishBill={props.finishBill}/>
                    </div>
                );
            })}
        </>
    );
}
