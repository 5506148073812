import { Container } from "@mui/system";
import React from "react";
import { Outlet} from "react-router-dom";
import AdminNavBar from "../components/Header/AdminNavBar";

const AdminPage = () => {
    return (
        <>
            <AdminNavBar />
            <Container maxWidth="xl" sx={{pt: 7}}>
                <Outlet />
            </Container>
        </>
    );
};

export default AdminPage;
