import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { API, BILL, MENU, WAITING } from "../API/constant";
import SkeletonBill from "../components/Bill/Skeleton";
import SnackBarAnnounce from "../components/Menu/SnackBarAnnounce";
import WaitingOrderComponent from "../components/WaitingOrder/WaitingOrderComponent";

const WaitingOrderPage = () => {
    const [Waiting, setWaiting] = useState(null);
    const [menu, setMenu] = useState(null);
    const [Bill, setBill] = useState(null);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [announceCount, setAnnounceCount] = useState(0);

    const fetchMenu = () => {
        axios.get(API + MENU).then((res) => {
            setMenu(res.data);
        });
    };

    const fetchWaiting = () => {
        axios.get(API + WAITING).then((res) => {
            setWaiting(res.data);
        });
    };

    const fetchBill = () => {
        axios.get(API + BILL).then((res) => {
            setBill(res.data);
        });
    };

    useEffect(() => {
        fetchMenu();
        fetchWaiting();
        fetchBill();
    }, []);

    const responseSuccess = (res) => {
        fetchWaiting();
        fetchBill();
        setSuccess(res.data.message);
        setAnnounceCount((announceCount) => announceCount + 1);
        setError("");
    };

    const responseFail = (res) => {
        setSuccess("");
        setAnnounceCount((announceCount) => announceCount + 1);
        setError(res.response.data.message);
    };

    const pushWaitingToOrder = (_id) => {
        axios
            .put(API + WAITING + "/" + _id)
            .then((res) => {
                console.log(res);
                responseSuccess(res);
            })
            .catch((res) => {
                console.log(res);
                responseFail(res);
            });
    };

    const cancelWaiting = (_id) => {
        axios
            .delete(API + WAITING + "/" + _id)
            .then((res) => {
                console.log(res);
                responseSuccess(res);
            })
            .catch((res) => {
                console.log(res);
                responseFail(res);
            });
    };

    return (
        <Box>
            <h1>This is the waiting order</h1>
            {Waiting && menu && Bill ? (
                <WaitingOrderComponent
                    pushWaitingToOrder={pushWaitingToOrder}
                    cancelWaiting={cancelWaiting}
                    menu={menu}
                    Waiting={Waiting}
                    Bill={Bill}
                />
            ) : (
                <SkeletonBill />
            )}
            <SnackBarAnnounce
                success={success}
                error={error}
                count={announceCount}
            />
        </Box>
    );
};

export default WaitingOrderPage;
